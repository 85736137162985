/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';

const PhoneNumberField = ({ ...props }) => {
  const { t } = useTranslation();
  const [field, meta] = useField('phoneNumber');

  return (
    <TextField
      {...field}
      name="phoneNumber"
      label="Teléfono Celular"
      variant="filled"
      fullWidth
      error={meta.touched && !!meta.error}
      helperText={
        meta.touched && meta.error ? meta.error : ' '
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">+{t('Phone prefix')}</InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PhoneNumberField;
