import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';

const EmailField = ({ ...props }) => {
  const [field, meta] = useField('email');

  return (
    <TextField
      {...field}
      name="email"
      label="Correo Electrónico"
      variant="filled"
      fullWidth
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : ' '}
      {...props}
    />
  );
};

export default EmailField;
